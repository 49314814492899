import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Dns,
  People,
  Inventory,
  Settings,
  ShoppingCart,
  KeyboardArrowDown,
  KeyboardArrowUp,
  AccountCircle,
  Warehouse,
  Groups,
  Engineering,
  Paid,
  PointOfSale,
  ViewStream,
  Shop,
  Sync,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  ClickAwayListener,
  Collapse,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SwipeableDrawer,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Global from "../../utils/GlobalValues";
import ResetDialog from "../ResetDialog";
import httpclient from "../../utils/httpClient"
import env from "../../utils/env";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const drawerWidth = 320;


const openedMixin = (theme) => ({
  width: drawerWidth,
  //backgroundColor: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.dark,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: "0px",
  // backgroundColor: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.dark,

  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  marginLeft: "20px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  "& img": {
    width: "200px",
    // height: "45px",
    marginTop: "7px",
  },
  "& a": {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    display: "flex",
    alignItems: "center"
  },
  "& h3": {
    letterSpacing: "1px",
    fontSize: "25px",
    margin: "0"
  },
  "& small": {
    fontSize: "13px",
    position: "relative",
    top: "10px",
    left: "-3px",
  }
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      // width: `calc(100% - 300px)`,
      width: "100%",
      zIndex: theme.zIndex.drawer - 1
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.primary.dark,
  // backgroundColor: theme.palette.primary.light,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Swipeable = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    //backgroundColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MultiLevelList = styled(List)(({ theme }) => ({
  marginLeft: "30px",
  // borderLeft: `2px solid ${theme.palette.primary.light}`,
  borderLeft: `2px solid ${theme.palette.primary.dark}`,
}));

const MenuListButton = styled(ListItemButton)(({ theme }) => ({
  opacity: "0.7",
  transition: "0.3s",
  fontWeight: "400",
  margin: "5px 8px",
  borderRadius: "10px",
  // color: theme.palette.primary.main,
  color: theme.palette.primary.light,
  "& svg": {
    // color: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    fontSize: "22px"
  },
  "& span": {
    fontWeight: "600",
    fontSize: "15px",
  },
  "&:hover": {
    opacity: "1",
  },
  "&:active": {
    opacity: "1",
    // background: theme.palette.primary.dark
  },
  "&:focus": {
    opacity: "1",
    // background: theme.palette.primary.dark
  },
}));



const AvatarDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& span": {
    color: theme.palette.primary.light,
    fontSize: "18px",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));





const drawerList = [


  // { menu: "POS", link: "/pos", icon: <Shop /> },

  { menu: "Dashboard", link: "/", icon: <Dashboard /> },


  {
    menu: "Products",
    link: "",
    icon: <ShoppingCart />,
    multiLevel: true,
    multiMenus: [
      { menuName: "Products", link: "/products/list_product_variations" },
      { menuName: "Product Categories", link: "/products/list_product_categories" },
      { menuName: "Product Groups", link: "/products/list_product_groups" },
      { menuName: "Product Matrix Dimensions", link: "/products/list_matrix_dimension" },

    ],
  },
  {
    menu: "Inventory",
    link: "",
    icon: <Inventory />,
    multiLevel: true,
    multiMenus: [
      { menuName: "Inventories", link: "/list_inventory_registrations" },
      { menuName: "Inventory Write Off", link: "/list_inventory_writeOff" },
      { menuName: "Inventory Transfer", link: "/list_inventory_transfer" },
      { menuName: "Reason Codes", link: "/list_reason_codes" },

    ],
  },
  // {
  //   menu: "Settings",
  //   link: "",
  //   icon: <Settings />,
  //   multiLevel: true,
  //   multiMenus: [
  //     { menuName: "Website Settings", link: "/settings/website_settings" },
  //     //{ menuName: "Add Products", link: "/users/add_user" },
  //     // { menuName: "Add User", link: "/users/add_user" },
  //   ],
  // },

  {
    menu: "Customers",
    link: "",
    icon: <Groups />,
    multiLevel: true,
    multiMenus: [
      { menuName: "Customers Accounts", link: "/customers/list_customers" },
      { menuName: "Customer Groups", link: "/customers/list_customer_groups" },

    ],
  },

  {
    menu: "Warehouses",
    link: "",
    icon: <Warehouse />,
    multiLevel: true,
    multiMenus: [
      { menuName: "Warehouses", link: "/warehouses/list_warehouses" },
      { menuName: "Opening/Closing Days", link: "/warehouses/list_opening_closing_days" },

    ],
  },

  {
    menu: "Employees",
    link: "",
    icon: <Engineering />,
    multiLevel: true,
    multiMenus: [
      { menuName: "Employees", link: "/employees/list_employees" },

    ],
  },
  {
    menu: "Transactions",
    link: "",
    icon: <PointOfSale />,
    multiLevel: true,
    multiMenus: [

      { menuName: "Sales Transaction", link: "/transactions/list_sales_transaction" },
      { menuName: "Purchase Transaction", link: "/transactions/list_purchase_transaction" },
      { menuName: "Sync Issue Sales Transaction", link: "/transactions/list_sync_issue_sales_transaction" },

    ],
  },
  {
    menu: "Payment",
    link: "",
    icon: <Paid />,
    multiLevel: true,
    multiMenus: [

      { menuName: "Payment", link: "/payments/list_payment" },

    ],
  },

  {
    menu: "Sync",
    link: "",
    icon: <Sync />,
    multiLevel: true,
    multiMenus: [

      { menuName: "Resync School", link: "/sync/resync_school" },
      { menuName: "Resync Store", link: "/sync/resync_store" },
      { menuName: "Resync Product", link: "/sync/resync_product" },
      { menuName: "Resync Generic Product", link: "/sync/resync_generic_product" },
      { menuName: "Resync SOH", link: "/sync/resync_soh" },

    ],
  },


  {
    menu: "Users",
    link: "",
    icon: <AccountCircle />,
    multiLevel: true,
    multiMenus: [
      { menuName: "List Users", link: "/get_users" },
      { menuName: "Add User", link: "/add_user" },

    ],
  },


];



export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const location = useLocation();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [currMenuName, setCurrMenuName] = React.useState("");
  const [menuLevelOpen, setMenuLevelOpen] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);

  const [openResetDialog, setOpenResetDialog] = React.useState(false);
  const [openActiveDialog, setOpenActiveDialog] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messageState, setMessageState] = React.useState("");

  const [viewDetails, setViewDetails] = React.useState({});
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  const navigate = useNavigate();
  var loginData = localStorage.getItem("user-login");

  //var userValue = JSON.parse(userData);
  var loginValue = JSON.parse(loginData);

  React.useEffect(() => {
    resize()
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  const resize = () => {
    setScreenWidth(window.innerWidth);
  }

  const handleDrawerOpen = () => {
    // setOpen(true);
    setOpen(prev => !prev);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleDrawerMobileOpen = () => {
    setMobileOpen(true);
    // setOpen(prev => !prev);
  };

  const handleDrawerMobileClose = () => {
    setMobileOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setOpenPopper(false);

    navigate("/user-login");
  };
  // const handleReset = (row) => {
  //   setOpenResetDialog(true);
  //   setViewDetails(row);
  // };

  const handleMenuDown = (name) => {
    setCurrMenuName(name);
    if (name !== currMenuName) {
      setMenuLevelOpen(true);
    } else if (name === currMenuName) {
      setMenuLevelOpen((prev) => !prev);
    } else {
      setMenuLevelOpen(false);
    }
  };

  const handleEditProfile = (id) => {
    setOpenPopper(false);

    navigate(`/get_user/${loginValue.id}`);

  };
  const handleResetPassword = (row) => {
    setOpenResetDialog(true);
    setViewDetails(row);
  };

  const sendReset = (call) => {
    if (call.open === false) {
      setOpenResetDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      httpclient
        .post(`updatePassword/${loginValue.id}?env=${env.value}`, {
          oldPassword: call.oldPassword,
          password: call.password,
          password_confirmation: call.password_confirmation,
        })
        .then(({ data }) => {
          if (data.status === 200) {
            setSnackOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenActiveDialog(false);                                                                                       
            setViewDetails({});
            //getAllUsers();
          } else {
            setSnackOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };




  const handlePopperOpen = () => {
    setOpenPopper((prev) => !prev);
  };

  const handleClosePopper = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenPopper(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenPopper(false);
    } else if (event.key === "Escape") {
      setOpenPopper(false);
    }
  };

  const MenuDiv = styled("div")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: mobileOpen ? "none" : "block",
    },
  }));

  const DesktopMenuDiv = styled("div")(({ theme }) => ({
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  return (
    <div>


      <Box sx={{ display: "flex" }}>

        <CssBaseline />

        {/* NAVBAR */}
        <AppBar position="fixed" open={screenWidth > 768 ? open : mobileOpen}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >

            <DesktopMenuDiv>
              <IconButton onClick={handleDrawerOpen}>
                {open ?
                  <ChevronLeft fontSize="large" style={{ color: "#fff" }} />
                  :
                  <MenuIcon fontSize="large" style={{ color: "#fff" }} />

                }
              </IconButton>
            </DesktopMenuDiv>

            <MenuDiv>
              <IconButton onClick={handleDrawerMobileOpen}>
                <MenuIcon fontSize="large" style={{ color: "#fff" }} />
              </IconButton>
            </MenuDiv>


            <AvatarDiv>
              <span>{loginValue.first_name}</span><span>{loginValue.last_name}</span>
              <Avatar
                alt={loginValue.username}
                src="/icon.png"
                sx={{
                  width: 43,
                  height: 43,
                  cursor: "pointer",
                  border: `2px solid #fafafa`,
                }}
                onClick={handlePopperOpen}
                ref={anchorRef}

              />

              <Popper
                open={openPopper}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start" ? "left top" : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClosePopper}>
                        <MenuList
                          autoFocusItem={openPopper}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleEditProfile}>
                            Edit Profile
                          </MenuItem>
                          <MenuItem onClick={handleResetPassword}>
                            Change Password
                          </MenuItem>

                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>

                  </Grow>
                )}
              </Popper>
            </AvatarDiv>

          </Toolbar>
        </AppBar>
        {/* NAVBAR */}
        {/* SIDEBAR OR DRAWER */}
        {screenWidth > 768 ? (
          <Drawer open={open} variant="permanent">
            <DrawerHeader>
              <Link to="/">
                {/* <h3>Sync Care</h3> */}
                <img src="/synccare-2.png" alt="synccare_logo" />
                <small>{Global.version}</small>
              </Link>
            </DrawerHeader>
            <Divider />
            <List>
              {drawerList.map((list, index) => (
                <>
                  {list.menu === "Users" && loginValue.type !== "superadmin" ? null : (
                    <ListItem
                      key={list.menu}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <Link to={list.link} style={{ textDecoration: "none" }}>
                        <MenuListButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            background:
                              location.pathname === list.link
                                ? theme.palette.secondary.main
                                : "transparent",
                            opacity:
                              location.pathname === list.link ? "1" : "0.7",
                          }}
                          onClick={() =>
                            handleMenuDown(list.multiLevel ? list.menu : "")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 1.6 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {list.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={list.menu}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                          {list.multiLevel ? (
                            currMenuName === list.menu ? (
                              <KeyboardArrowDown />
                            ) : (
                              <KeyboardArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </MenuListButton>
                      </Link>
                    </ListItem>
                  )}
                  {list.multiLevel && (
                    currMenuName === list.menu && (
                      <Collapse
                        in={menuLevelOpen}
                        timeout="auto"
                        unmountOnExit
                      >
                        <>
                          <Divider />
                          <MultiLevelList>
                            {list.multiLevel &&
                              list.multiMenus.map((sub, index) => (
                                <ListItem disablePadding sx={{ display: "block" }}>
                                  <Link
                                    to={sub.link}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <MenuListButton
                                      sx={{
                                        minHeight: 48,
                                        justifyContent: open ? "initial" : "center",
                                        px: 2.5,
                                        background:
                                          location.pathname === sub.link
                                            ? theme.palette.secondary.main
                                            : "transparent",
                                        opacity:
                                          location.pathname === sub.link
                                            ? "1"
                                            : "0.7",
                                      }}
                                    >
                                      <ListItemText
                                        primary={sub.menuName}
                                        sx={{ opacity: open ? 1 : 0 }}
                                      />
                                      <ChevronRight />
                                    </MenuListButton>
                                  </Link>
                                </ListItem>
                              ))}
                          </MultiLevelList>
                        </>
                      </Collapse>
                    )
                  )}
                </>
              ))}
            </List>

          </Drawer>
        ) : (
          <Swipeable
            anchor="left"
            open={mobileOpen}
            onOpen={handleDrawerMobileOpen}
            onClose={handleDrawerMobileClose}
          >
            <DrawerHeader>
              <Link to="/">
                {/* <h3>Sync Care</h3> */}
                <img src="/synccare-2.png" alt="synccare_logo" />

                <small>{Global.version}</small>
              </Link>
              {/* <IconButton onClick={handleDrawerMobileClose}>
            <ChevronLeftIcon fontSize="large" color="secondary" />
          </IconButton> */}
            </DrawerHeader>
            <Divider />
            <List>
              {drawerList.map((list, index) => {
                if (list.menu === "Users" && loginValue.type !== "superadmin") {
                  return null;
                }

                return (
                  <>
                    <ListItem
                      key={list.menu}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <Link to={list.link} style={{ textDecoration: "none" }}>
                        <MenuListButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            background:
                              location.pathname === list.link
                                ? theme.palette.secondary.main
                                : "transparent",
                            opacity: location.pathname === list.link ? "1" : "0.7",
                          }}
                          onClick={() =>
                            handleMenuDown(list.multiLevel ? list.menu : "")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {list.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={list.menu}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                          {list.multiLevel ? (
                            currMenuName === list.menu ? (
                              <KeyboardArrowDown />
                            ) : (
                              <KeyboardArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </MenuListButton>
                      </Link>
                    </ListItem>
                    {list.multiLevel && (
                      currMenuName === list.menu && (
                        <Collapse
                          // in={((list.menu === currMenuName) && menuLevelOpen) ? menuLevelOpen : false }
                          in={menuLevelOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <>
                            <Divider />
                            <MultiLevelList>
                              {list.multiLevel &&
                                list.multiMenus.map((sub, index) => (
                                  <ListItem disablePadding sx={{ display: "block" }}>
                                    <Link
                                      to={sub.link}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <MenuListButton
                                        sx={{
                                          minHeight: 48,
                                          justifyContent: open ? "initial" : "center",
                                          px: 2.5,
                                          background:
                                            location.pathname === list.link
                                              ? theme.palette.secondary.main
                                              : "transparent",
                                          opacity:
                                            location.pathname === sub.link
                                              ? "1"
                                              : "0.7",
                                        }}
                                      >
                                        <ListItemText
                                          primary={sub.menuName}
                                          sx={{ opacity: open ? 1 : 0 }}
                                        />
                                      </MenuListButton>
                                    </Link>
                                  </ListItem>
                                ))}
                            </MultiLevelList>
                          </>
                        </Collapse>
                      )
                    )}
                  </>
                );
              })}
            </List>

          </Swipeable>
        )}



        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            height: "100% !important",
            width: "100%",
          }}
        >
          <DrawerHeader />

          {children}

        </Box>

      </Box>
      {openResetDialog && (
        <ResetDialog viewDetails={viewDetails} sendReset={sendReset} />

      )}


      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackOpen}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>





    </div>





  );

}
